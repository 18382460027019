import React, { useEffect, useRef, useState } from "react"
import Flickity from "react-flickity-component"
import "flickity/dist/flickity.min.css"
import { Link, graphql } from "gatsby"


//component
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LiveChat from "../../components/Home/livechat";
import TestimonialsText from "../../components/Home/testimonials-text"

// img
import pairBannerimg from "../../../images/uploads/pairdesi/pair-main-banner.png"
import pairResponsiveMobile from "../../../images/uploads/pairdesi/pair-responsive-mobile.png"
import pairHoriimg from "../../../images/uploads/pairdesi/pair-hori-mobile.png"
import pairChallangebg1 from "../../../images/uploads/pairdesi/pair-challange-bg.png"
import pairChallangemobile from "../../../images/uploads/pairdesi/pair-challange-mobile.png"
import pairSolu1 from "../../../images/uploads/pairdesi/pair-solu-1.png"
import pairLikedscreen from "../../../images/uploads/pairdesi/pair-liked-screen.png"
import pairLapmob from "../../../images/uploads/pairdesi/pair-lap-mob.png"
import pairMainwire from "../../../images/uploads/pairdesi/pair-main-wirefram.png"
import pairMainui from "../../../images/uploads/pairdesi/pair-main-ui.png"
import pairDesiss1 from "../../../images/uploads/pairdesi/decodeup-pairdesi-slider-block-screen-resp.png"
import pairDesiss2 from "../../../images/uploads/pairdesi/decodeup-pairdesi-slider-chat-list-resp.png"
import pairDesiss3 from "../../../images/uploads/pairdesi/decodeup-pairdesi-slider-chat-responsive.png"
import pairDesiss4 from "../../../images/uploads/pairdesi/decodeup-pairdesi-slider-chat-tablet-screen.png"
import pairDesiss5 from "../../../images/uploads/pairdesi/decodeup-pairdesi-slider-choose-plan.png"
import pairDesiss6 from "../../../images/uploads/pairdesi/decodeup-pairdesi-slider-complete-profile.png"
import pairDesiss7 from "../../../images/uploads/pairdesi/decodeup-pairdesi-slider-dashboard.png"
import pairDesiss8 from "../../../images/uploads/pairdesi/decodeup-pairdesi-slider-dashboard-tablet-screen.png"
import pairDesiss9 from "../../../images/uploads/pairdesi/decodeup-pairdesi-slider-matches.png"
import pairDesiss10 from "../../../images/uploads/pairdesi/decodeup-pairdesi-slider-matches-resp.png"
import pairDesiss11 from "../../../images/uploads/pairdesi/decodeup-pairdesi-slider-matches-tablet-screen.png"
import pairDesiss12 from "../../../images/uploads/pairdesi/decodeup-pairdesi-slider-signup-step.png"
import pairDesiss13 from "../../../images/uploads/pairdesi/decodeup-pairdesi-slider-user-profile.png"
import pairDesiss14 from "../../../images/uploads/pairdesi/decodeup-pairdesi-slider-user-profile-img.png"

import addcs1 from "../../../images/uploads/decodeup-additional-case-study-square-one-media.png"
import addcs2 from "../../../images/uploads/decodeup-additional-case-study-medication-tracker.png"

const PairDesi = props => {
  const SEOdata = props.data.pageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.pageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.pageData.edges[0].node.frontmatter?.Hreflang
  const [margin, setMargin] = useState()
  const flRef = useRef()

  useEffect(() => {
    let box = document.querySelector(".container")
    let width = box.offsetWidth
    let innerw = window.innerWidth
    let paddingApplied = innerw - width

    setMargin(paddingApplied)
  })

  const options = {
    lazyLoad: true,
    prevNextButtons: true,
    cellAlign: "left",
    contain: true,
    freeScroll: true,
    pageDots: false,
    percentPosition: true,
    adaptiveHeight: true,
  }

  const onMouseEnter = () => {
    flRef.current.flkty.player.play()
  }
  const onMouseLeave = () => {
    flRef.current.flkty.player.stop()
  }
  return (
    <>
      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "pairdesi"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
          single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
          currentPageUrlPath={props.location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
          twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
          local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
          site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
          speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />

        {/* banner  section */}
        <section className="h-full xl:h-screen case-draft-section">
          <div className="h-full container mx-auto  ">
            <div className="pb-20 pt-1-63 md:py-2-00 lg:pt-64 h-full flex flex-col justify-center">
              <div className="mb-6-6 lg:mb-50 w-full  case-study-project-heading ">
                <h4 className="pr-5 lg:pl-0 mb-1-2 lg:mb-2-2 font-poppings-normal text-p3 lg:text-p2 text-term-primary">
                  Android, IOS & Web Dating App
                </h4>
                <h1 className="mb-3 lg:mb-5 font-poppings-bold text-h2 lg:text-h0 xl:text-h1 text-term-primary">
                  Indo-American Dating Platform
                </h1>
                <p className="w-full lg:w-11/20  font-worksans-normal text-p4  md:text-h5 lg:text-p2 text-term-primary">
                  Startups offer a world of possibilities and an assortment of
                  challenges. We know how to take ideas and create stunning,
                  interactive tools for a variety of audiences. Read below to
                  see how we helped this tech startup create an effective app
                  for their target audience and imagine what we can do for you.
                </p>
              </div>

              <div className="project-details-draft">
                <div className="flex flex-wrap lg:flex-nowrap">
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      CLIENT
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Timothy
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      COUNTRY
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      United States of America
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      SERVICES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      UI/UX Design, Development, Hosting and Maintenance
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      TECHNOLOGIES USED
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      React, Node, Android Native (Java/Kotlin) & iOS Swift
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      INDUSTRIES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Startups
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Banner img section */}
        <section className="relative pair-main-bg bg-center bg-contain bg-no-repeat">
          <div className="container mx-auto">
            <div className="relative">
              {/* main-mobile image  */}
              <img
                className="hidden md:block mx-auto "
                src={pairBannerimg}
                alt="Pairdesi Decodeup Technologies"
              />
              <img
                className="pair-mob-banner block md:hidden mx-auto "
                src={pairResponsiveMobile}
                alt="Pairdesi Decodeup Technologies"
              />
            </div>
          </div>
        </section>

        {/* About Project section */}
        <section className="pt-60 lg:pt-1-40 mb-6 pair-bg-light">
          <div className="container mx-auto">
            <div className="pb-8 lg:pb-20 flex flex-wrap sm:flex-nowrap gap-8 md:gap-8 lg:gap-16">
              <div className="w-full lg:w-1/2">
                <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Indian Americans Matchmaking
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  This client arrived with some exciting ideas for a brand new
                  website and mobile application to address Indian-American
                  dating challenges in the States.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The client believed strongly that everyone has a perfect
                  partner waiting for them, but without the right tools it's
                  difficult to find your person. Their solution was to provide a
                  platform that empowered those within the Indian American
                  community to locate compatible partners wherever they reside.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Product launches and startups are among our favorite projects
                  because they help clients go from original ideas to polished
                  products.
                </p>
              </div>
              <div className="w-full lg:w-1/2">
                <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Making customers feel special
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  As a company that previously began as a startup, we understand
                  just how frustrating and exciting it can be to start a new
                  company. While the process is exhilarating, you can also face
                  many worries and challenges.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Guiding new startups through this process is our way of giving
                  back and making the experience easier for new business owners
                  around the world. We've felt all the emotions you're feeling
                  now and one thing's for certain: you will get through this.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  As DecodeUp, you will always know there's a qualified
                  individual who has your back and best interests at heart. We
                  collectively have decades of experience in marketing, design,
                  project management, tech development and more. Avoid the
                  pitfalls and claim success with a personalized team dedicated
                  to your idea.
                </p>
              </div>
            </div>

            <div>
              <img
                className="mx-auto "
                src={pairHoriimg}
                alt="Pairdesi Decodeup Technologies"
              />
            </div>
          </div>
        </section>

        {/* Challanges section */}
        <section className="mb-60 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="w-full">
              <h2 className="mb-4 md:mb-6-6 lg:mb-14 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary text-left lg:text-center">
                The Challenges
              </h2>
            </div>

            <div className="flex flex-wrap sm:flex-nowrap gap-8 md:gap-8 lg:gap-16">
              <div className="w-full lg:w-1/2">
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  When we began our partnership, our client brought a lot to the
                  table, namely a fully developed vision for their app. This
                  vision included visuals, specific features and additional
                  points for various stages of the mobile/web app development
                  and launch. Some of their desires included state-of-the-art
                  technology that allowed users to chat across applications and
                  run in the background with sophisticated backend modules.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Because they had already laid out their mission it was easy to
                  collaborate. They were seeking a partner who would be able to
                  realize their goals and understood the impact they wanted the
                  app to make. We guided them to the proper technology to make
                  the idea a reality.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Together, we created a stunning app.
                </p>
              </div>
              <div className="w-full lg:w-1/2">
                <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Here are some of the features our client desired
                </h3>
                <ul className="mb-5 flex flex-col gap-3 md:gap-5 list-none pairdesi-solution-ul text-term-primary font-worksans-normal text-p4 lg:text-p3 xl:text-p2">
                  <li className="ml-6 lg:ml-10 relative">
                    Backend module to match users based on specific information
                  </li>
                  <li className="ml-6 lg:ml-10 relative">
                    Chat function that worked across platforms
                  </li>
                  <li className="ml-6 lg:ml-10 relative">
                    Realtime chat support
                  </li>
                  <li className="ml-6 lg:ml-10 relative">
                    Security features to block and report users or suspicious
                    activity
                  </li>
                  <li className="ml-6 lg:ml-10 relative">
                    Form that collected relevant data from users in multiple
                    steps to facilitate matchmaking
                  </li>
                  <li className="ml-6 lg:ml-10 relative">
                    Capacity to upload and store HD photos on users' profiles &
                    Bio
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Banner img section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40 ">
          <div className="container mx-auto">
            <div className="flex flex-row gap-4 lg:gap-12 justify-center md:justify-between">
              <div className="hidden mms:block">
                <img
                  className="mx-auto"
                  src={pairChallangebg1}
                  alt="Pairdesi Decodeup Technologies"
                />
              </div>
              <div className="pair-mob-msg">
                <img
                  className="mx-auto"
                  src={pairChallangemobile}
                  alt="Pairdesi Decodeup Technologies"
                />
              </div>
            </div>
          </div>
        </section>

        {/* solutions */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="w-full mb-8 lg:mb-16">
              <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary text-center">
                Solutions
              </h2>
            </div>
            {/* 1 */}
            <div className="flex flex-wrap sm:flex-nowrap gap-4 md:gap-8 lg:gap-16 items-center">
              <div className="w-full sm:w-1/2">
                <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Hello, nice to meet you...
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We began with a meeting to brainstorm solutions to the
                  challenges and needs of our clients. We ask all clients to
                  identify the following:
                </p>
                <ul className="mb-5 flex flex-col gap-3 md:gap-5 list-none pairdesi-solution-ul text-term-primary font-worksans-normal text-p4 lg:text-p3 xl:text-p2">
                  <li className="ml-6 lg:ml-10 relative">
                    Goals for the project
                  </li>
                  <li className="ml-6 lg:ml-10 relative">
                    Necessary features and capabilities
                  </li>
                  <li className="ml-6 lg:ml-10 relative">
                    Backend essentials and data management
                  </li>
                  <li className="ml-6 lg:ml-10 relative">
                    Information that needs to be captured from users
                  </li>
                  <li className="ml-6 lg:ml-10 relative">
                    Parameters for app (in this case matchmaking)
                  </li>
                  <li className="ml-6 lg:ml-10 relative">
                    Overall look and feel of the site or app
                  </li>
                  <li className="ml-6 lg:ml-10 relative">
                    Most important action for app users
                  </li>
                </ul>
              </div>
              <div className="w-full sm:w-1/2">
                <img
                  className="pair-sol-img mx-auto"
                  src={pairSolu1}
                  alt="Pairdesi Decodeup Technologies"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Banner img section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="relative w-full h-full pair-img-ani">
              {/* <div className="mx-auto absolute top-0 left-0 pair-img-1"> */}
              <div className="mx-auto absolute w-full h-full top-0 left-0">
                <img
                  className="mx-auto absolute w-full h-full"
                  src={pairMainwire}
                  alt="Pairdesi Decodeup Technologies"
                />
              </div>
              {/* <div className="mx-auto absolute top-0 left-0 pair-img-2"> */}
              <div className="mx-auto absolute w-full h-full top-0 left-0  pair-img-border">
                <img
                  className="mx-auto  absolute w-full h-full  clip-rec"
                  src={pairMainui}
                  alt="Pairdesi Decodeup Technologies"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="flex flex-wrap sm:flex-nowrap gap-4 md:gap-8 lg:gap-16 items-center">
              <div className="w-full sm:w-1/2">
                <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Customized App Management
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We created custom function in the backend upon the client's
                  request to facilitate easy data management for their team.
                  Features included:
                </p>
                <ul className="mb-5 flex flex-col gap-3 md:gap-5 list-none pairdesi-solution-ul text-term-primary font-worksans-normal text-p4 lg:text-p3 xl:text-p2">
                  <li className="ml-6 lg:ml-10 relative">
                    Users who come to system and verify all added details
                  </li>
                  <li className="ml-6 lg:ml-10 relative">
                    Matchmaking process they can do from here by drag and drop
                    to assign right possible matching for users.
                  </li>
                  <li className="ml-6 lg:ml-10 relative">
                    View reports of users and take action on required users.
                  </li>
                  <li className="ml-6 lg:ml-10 relative">
                    Chat with any users functionality
                  </li>
                  <li className="ml-6 lg:ml-10 relative">
                    Check & manage activated subscriptions details of users
                  </li>
                </ul>
              </div>
              <div className="w-full sm:w-1/2">
                <img
                  className="pari-cstm-app mx-auto"
                  src={pairLikedscreen}
                  alt="Pairdesi Decodeup Technologies"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="pb-10 mb-60 md:mb-20 xl:mb-1-40 pair-full">
          <div className="container mx-auto">
            <div className="w-full max-w-8-80 mx-auto text-center">
              <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                Fully Developed Apps for Mobile and Web
              </h3>
              <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                We took their ideas and needs and worked around the clock to
                develop a custom solution.
              </p>
              <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                Using our skills in data aggregation, app languages,
                customization and more we crafted a beautiful app that met every
                need. Did we mention we finished on time and within budget? It's
                really doesn't get any better than that. Whether your idea is
                large, small, simple or complex, our teams at DecodeUp are ready
                to serve you.
              </p>
            </div>
            <div className="mt-16 w-full">
              <img
                className="mx-auto"
                src={pairLapmob}
                alt="Pairdesi Decodeup Technologies"
              />
            </div>
          </div>
        </section>

        {/* Typographics */}

        <section className="mb-60 md:mb-20 xl:mb-1-40 ">
          <div className="container mx-auto">
            <div className="mb-6 md:mb-10 pair-typo">
              <div className="w-full">
                <h3 className="mb-8  xl:mb-16 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                  Typeface & Colors
                </h3>
              </div>
              <div className="w-full lg:w-1/2">
                <h3 className="mb-2 lg:mb-4 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Montserrat
                </h3>
                <h5 className="mb-1 text-term-primary opacity-70">
                  ABCDEFGHIJKLMNOPQRSTUVWXYZ
                </h5>
                <h5 className="mb-1 text-term-primary opacity-70">
                  abcdefghijklmnopqrstuvwxyz
                </h5>
                <h5 className="mb-1 text-term-primary opacity-70">
                  1234567890
                </h5>
              </div>
            </div>

            <div className="w-full">
              <div className="flex flex-wrap sm:flex-nowrap gap-2">
                <div>
                  <div className="pair-color-plate">
                    <h5 className="text-white font-poppings-bold text-center">
                      #771412
                    </h5>
                  </div>
                </div>
                <div>
                  <div className="pair-color-plate pair-color-plate-2">
                    <h5 className="text-white font-poppings-bold text-center">
                      #BEBEBE
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Result section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40 ">
          <div className="container mx-auto">
            <div className="w-full sm:w-2/3 lg:max-w-3xl">
              <h2 className="mb-4 lg:mb-8 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                The Result
              </h2>
              <p className="mb-2 lg:mb-3 xl:mb-6 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                We found great success with our soft launch: it was met with
                positivity from the initial users, who enjoyed the matching
                profiles features, chat functions and readily available support.
                For the Indian community in US, the app quickly became an
                impactful resource. We are happy to say this collaboration made
                lives more enjoyable. Our dedication and passion for creating
                value proved to be the perfect match.
              </p>

              <div className="w-full">
                <h3 className="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  We had a fantastic time working with Tim. And we can't wait to
                  work with you.
                </h3>
              </div>
            </div>
          </div>
        </section>

        {/* slider section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="case-study-slider-bg">
            <div className="container container-cor mx-auto">
              <div
                id="myid"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <Flickity
                  className={"case-studies-carousel cstm-du-casestudy-slider"}
                  elementType={"div"}
                  options={options}
                  ref={flRef}
                >
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={pairDesiss1}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={pairDesiss2}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={pairDesiss3}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={pairDesiss4}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={pairDesiss5}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={pairDesiss6}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={pairDesiss7}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={pairDesiss8}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={pairDesiss9}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={pairDesiss10}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={pairDesiss11}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={pairDesiss12}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={pairDesiss13}
                    alt="Pairdesi Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={pairDesiss14}
                    alt="Pairdesi Decodeup Technologies"
                  />
                </Flickity>
              </div>
            </div>
          </div>
        </section>

        {/* client testimonials carousel section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cor mx-auto">
            <div className="pr-2 pl-5-3 lg:pl-60 lg:pr-60 xl:px-90 xl:pl-1-41 xl:pr-1-41 2xl:pl-1-86 2xl:pr-1-87">
              <TestimonialsText />
            </div>
          </div>
        </section>

        {/* additional case studies section */}

        <section className="pb-50 md:pt-50 lg:pt-62 xl:pt-0 lg:pb-1-20">
          <div className="container mx-auto">
            <div className="pb-35 lg:pb-36 text-center">
              <h3 className="d-head-title text-rp1  lg:text-h3 font-poppings-bold text-term-primary">
                Additional Case Studies
              </h3>
            </div>
            <div className="h-full flex flex-wrap">
              <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-cyan">
                <Link
                  to="/case-study/luxury-real-estatemedia-managements"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Luxury Real Estate Media Management System
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className="w-full h-full"
                      src={addcs1}
                      alt="Square One Media Productions Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
              <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-background-case">
                <Link
                  // to={post.fields.slug}
                  to="/case-study/medications-tracker"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Medications Tracker
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className="w-full h-full"
                      src={addcs2}
                      alt="Medication Tracker Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <LiveChat />
      </Layout>
    </>
  )
}

export default PairDesi

export const pageQuery = graphql`
query pairdesipagequery {
  pageData: allMarkdownRemark(
    filter: { fileAbsolutePath: { regex: "/case-studies/indo-american-dating-platform.md/" } }
  ) {
    edges {
      node {
        frontmatter {
          seo_metadata {
            seo_title
            seo_keyword
            seo_description
            image_format
            feature_image_height
            img_width
            facebook_url
            twitter_page_username
            twitter_image_alt_tag
            twitter_estimated_reading_lable
            twitter_estimated_reading_time
            page_publish_date
            page_last_modified_time
            seo_Image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200)
              }
            }
            seo_Single_Image
          }
          Schema {
            sitelinks_schema
            breadcrumb_schema
            logo_schema
            organization_schema
            aggregate_rating_schema
            local_business_schema
            site_navigation_schema
            speakable_specification_schema
          }
          Hreflang {
            hreflang_1
            hreflang_2
            hreflang_3
            hreflang_4
          }
        }
      }
    }
  }     
}

`;