import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import { Fade } from 'react-reveal'
const Livechat = () => {

  // const onChatBtnClick = (e) => {
  //   e.preventDefault();
  //   window.HubSpotConversations.widget.open();
  // }

  const data = useStaticQuery(graphql`
  query {
    content: markdownRemark(fileAbsolutePath: {regex: "/free-consultation.md/"}) {
      frontmatter {
        sub_title
        description
        bg_image {
          alt
          image {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
  `)
  const info = data.content.frontmatter

  return (
    <>
      <section className="pt-10 bg-background-primary overflow-hidden">
      <Fade bottom distance="60px" delay={300}>
        <div className="container mx-auto container-full">
            <div className="relative">
            <BgImage image={getImage(info.bg_image.image)} alt={info.bg_image.alt}>
                <div className="d-livechat--main relative ">
                  <div className="pt-61 pb-49 pr-8 pl-38 lg:py-95 xl:pt-86 xl:pb-1-13 xl:pl-40 xl:pr-1-80 relative z-10 items-center flex justify-between flex-wrap">
                    <div className="w-full md:w-7/11">
                    <Fade bottom distance="60px" delay={300}>
                        <h6 className="pb-1 lg:pb-3-1 font-poppings-bold uppercase text-rh6 lg:text-h6 text-cyan">
                          {info.sub_title}
                        </h6>
                        </Fade>
                        <Fade bottom distance="60px" delay={300}>
                        <p className="font-worksans-bold text-rp1 lg:text-p1 text-white">
                          {info.description}
                        </p>
                        </Fade>
                    </div>
                    <Fade bottom distance="60px" delay={300}>
                    <div className="pt-6-3 lg:pt-5-3 w-full md:w-4/11 md:text-right">
                        <a
                          href="/get-in-touch"
                          className="py-3 pl-34 pr-35 relative z-10 font-poppings-bold inline-block text-center text-dark-blue text-h5 font-bold bg-celeste shadow-1 hover:shadow-none transition-all duration-300 ease-in-out delay-0"
                        >
                          Connect With Us
                        </a>
                    </div>
                    </Fade>
                  </div>
                </div>
              </BgImage>
            </div>
        </div>
        </Fade>
      </section>
    </>
  )
}

export default Livechat;
