import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import Flickity from 'react-flickity-component'
import 'flickity/dist/flickity.min.css';
import { Fade } from 'react-reveal';

class TestimonialsText extends Component {
  state = {
    showAll: false,
    ind: 0,
  }
  componentDidMount = () => {
    this.fliky.on('change', (index) => {
      if (this.state.showAll) {
        this.setState({ showAll: false });
      }
    })
  }
  onMouseLeave = () => {
    this.fliky.playPlayer()
  }
  showMore = (event, index) => {
    const el = event.target.closest("a");
    if (el && event.currentTarget.contains(el)) {
      this.setState({ showAll: true, ind: index });
    }
  }
  showLess = (event, index) => {
    const el = event.target.closest("a");
    if (el && event.currentTarget.contains(el)) {
      this.setState({ showAll: false, ind: index });
    }
  }

  contntLength = (content) => {
    const lenCnt = content.substr(3, content.length - 4)
    const final_l = lenCnt.length
    return final_l
  }
  render() {
    const { showAll, ind } = this.state;
    const limit = 240;
    const flickityOptions = {
      prevNextButtons: false,
      draggable: true,
      pageDots: false,
      wrapAround: true,
      autoPlay: 8000
    }
    return (
      <>
        <Fade bottom distance="60px" delay={100}>
          <div className=" flex flex-wrap">
            <div className="md:pt-2-1 w-full md:w-2/8">
              <Fade bottom distance="60px" delay={100}>
                <hr className="border-cyan w-1-06 pb-2-3"></hr>
                <h6 className="font-poppings-bold text-rh6 lg:text-h6 text-cyan">
                  IN THEIR WORDS
                </h6>
              </Fade>
            </div>
            <StaticQuery
              query={graphql`
              query {
                text_testi: allMarkdownRemark(
                  limit: 3
                  filter: {frontmatter: {templateKey: {eq: "testimonial"},testimonial_type: {eq: "CTT"}}}
                  ) {
                  edges {
                    node {
                      frontmatter {
                        title
                        templateKey
                        description
                        testimonial_type
                        date
                      }
                      id
                      html
                    }
                  }
                }
                }
                `}
              render={data => (
                <div className="w-full md:w-6/8">
                  <div aria-hidden="true" className="t-slider" onMouseLeave={this.onMouseLeave}>
                    <Fade bottom distance="60px" delay={100}>
                      {/* <div className="t-slider"> */}
                      <Flickity
                        flickityRef={c => this.fliky = c}
                        className={'testimonial-carousel'}
                        elementType={'div'}
                        options={flickityOptions}
                      >
                        {data.text_testi.edges &&
                          data.text_testi.edges.map(({ node: post }, index) => (
                            <div key={index} className="testimonial-cell">
                              {this.contntLength(post.html) <= limit ?
                                <p
                                  dangerouslySetInnerHTML={{ __html: post.html }}
                                  className="pt-5-3 pb-4-2 md:pt-0 lg:pb-5-1 pr-1 1md:pr-49 lg:pr-0 font-worksans-bold text-rp1 lg:text-p1 text-term-primary"></p>
                                :
                                (index === ind && showAll ?
                                  <>
                                    <p
                                      aria-hidden="true"
                                      onClick={(event) => this.showLess(event, index)}
                                      className="pt-5-3 pb-4-2 md:pt-0 lg:pb-5-1 pr-1 1md:pr-49 lg:pr-0 font-worksans-bold text-rp1 lg:text-p1 text-term-primary"
                                      dangerouslySetInnerHTML={{ __html: `${(post.html.substring(0, this.contntLength(post.html)))} <a class="cursor-pointer font-poppings-bold text-cyan text-rp3 lg:text-p2 underline">see less</a>` }}
                                    />
                                  </>
                                  :
                                  <>
                                    <p
                                      aria-hidden="true"
                                      onClick={(event) => this.showMore(event, index)}
                                      className="pt-5-3 pb-4-2 md:pt-0 lg:pb-5-1 pr-1 1md:pr-49 lg:pr-0 font-worksans-bold text-rp1 lg:text-p1 text-term-primary"
                                      dangerouslySetInnerHTML={{ __html: `${(post.html.substring(0, limit))} <a class="cursor-pointer font-poppings-bold text-cyan text-rp3 lg:text-p2 underline">see more</a>` }}
                                    />
                                  </>
                                )}
                              <p className="font-worksans-normal text-xs lg:text-sm ss:leading-9 text-term-primary">
                                <span className="font-worksans-bold">- {post.frontmatter.title}</span> {post.frontmatter.description}</p>
                            </div>
                          ))}
                      </Flickity>
                    </Fade>
                  </div>
                </div>
              )}
            />
          </div>
        </Fade>
      </>
    );
  }
}

export default TestimonialsText;